<script lang="ts">
	import { getContext } from 'svelte'

	type TabContext = {
		setIndex: () => number
		registerTab: (tab: Object) => void
		setActiveTab: (index: number) => void
		activeTab: any
	}

	export let title: string
	let { setIndex, registerTab, activeTab }: TabContext = getContext('tabContext')

	const tabIndex = setIndex()

	const tab = {
		index: tabIndex,
		title
	}

	registerTab(tab)
</script>

<div class={$activeTab === tabIndex ? 'block' : 'hidden'}>
	<slot />
</div>
