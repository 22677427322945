<script lang="ts">
	import { setContext } from 'svelte'
	import { writable } from 'svelte/store'

	type Tab = {
		index: number
		title: string
	}

	export let justify: 'start' | 'center' | 'end' = 'start'

	let tabs: Tab[] = []
	let activeTab = writable(0)
	let startIndex = -1

	let tabContext = {
		setIndex: () => {
			startIndex += 1
			return startIndex
		},
		registerTab: (tab: Tab) => {
			tabs.push(tab)
			tabs = tabs
		},
		setActiveTab: (index: number) => {
			activeTab.set(index)
		},
		activeTab
	}

	setContext('tabContext', tabContext)
</script>

<div
	class="page-fullwidth xl:page-wrapper hide-scrollbar mb-8 flex md:justify-{justify} overflow-x-scroll px-4"
>
	<div class="flex w-fit gap-4-res-25 border-b border-neutral-400 md:border-b-0">
		{#each tabs as tab}
			<button
				class="whitespace-nowrap border-b-4 p-4 {$activeTab === tab.index
					? 'border-primary-500 font-bold'
					: 'border-transparent'}"
				on:click={() => {
					tabContext.setActiveTab(tab.index)
				}}
			>
				{tab.title}
			</button>
		{/each}

		{#if tabs.length === 0}
			<div class="my-4 flex h-7 animate-pulse flex-row gap-4-res-25 whitespace-nowrap">
				<div class="h-full w-24 rounded-md bg-neutral-200" />
				<div class="h-full w-40 rounded-md bg-neutral-200" />
				<div class="h-full w-48 rounded-md bg-neutral-200" />
			</div>
		{/if}
	</div>
</div>
<div class="page-wrapper">
	<slot />
</div>
